<template>
  <div class="sticky-top news-header">

    <!--    <div v-if="checkRole('student') && true" class="text-center pt-2 pb-1"-->
    <!--         style="background-color: #e0e0e0; color: #333">-->
    <!--      <div>-->
    <!--        <p>Уважаемый студент!-->
    <!--          <br>-->
    <!--          Мы будем признательны Вам за участие в данном исследовании, целью которого является совершенствование-->
    <!--          <br>-->
    <!--          информационно-библиотечного обслуживания студентов, преподавателей и исследователей в Вашем университете.-->
    <!--          <br>-->
    <!--          Опрос анонимный, подписывать анкету не нужно.-->
    <!--          <br>-->
    <!--          Результаты опроса будут использованы только в обобщенном виде.-->
    <!--          <br>-->
    <!--          Благодарим Вас за сотрудничество, которое мы очень ценим!-->
    <!--          <br>-->
    <!--          <b><a href="https://forms.gle/wHeRrPQqV8EMPEmV8" target="_blank">https://forms.gle/wHeRrPQqV8EMPEmV8</a></b>-->

    <!--        </p>-->
    <!--      </div>-->
    <!--    </div>-->


    <!--    <div v-if="false" class="text-center pt-2 pb-1" style="background-color: #42d392">-->
    <!--      <h5>21, 22, 23 марта выходной</h5>-->
    <!--    </div>-->

    <div v-if="isMessageVisible&&checkRole('student')" class="text-center bg-danger" id="notification-header-text">
      <!--      <h5>Уважаемые студенты просим всех произвести оплату 3 транша до 01.03.2023 согласно договора. По всем вопросам обращаться по телефону 87719499944, кабинет 117 (по графику).</h5>-->
      <!--      <h5>ВСК 2 для 2 и 3 курсов закрывается 26 февраля в 23:59ч.</h5>-->
    </div>

    <div v-if="userDebts.length" class="notification-header">
      <div class="container">
        <div class="text-center pt-2 row" v-for="(d, dIndex) of userDebts" :key="'debts'+dIndex">
          <div v-if="d.education_debt" class="col">
            <h5>Долг за обучение: {{ d.education_debt }} тг</h5>
          </div>
          <div v-if="d.peni_debt" class="col">
            <h5>Долг пени: {{ d.peni_debt }} тг</h5>
          </div>
          <div v-if="d.other_debt" class="col">
            <h5>Прочий долг: {{ d.other_debt }} тг</h5>
          </div>
          <div v-if="d.total_debt" class="col">
            <h4>Общий долг: {{ d.total_debt }} тг</h4>
          </div>
        </div>

        <div class="py-2 text-center">
          <h5>
            Просим всех погасить задолженность 1 транша согласно договору.
          </h5>
          <h5>
            По вопросам обращаться в 117 кабинет, телефон: 2598008 городской,  +77719499944 сотовый
          </h5>
        </div>
      </div>
    </div>
  </div>

  <Menubar>
    <template #start>
      <div class="navbar-brand">
        <Button icon="pi pi-bars" @click="visibleLeft = true" class="me-3"/>
        <span class="site-name">
        <router-link to="/" class="navbar-brand" href="/">UIB</router-link>
      </span>
      </div>

    </template>
    <template #end>
      <Menubar :model="accountItems" class="accountMenu"/>
    </template>
  </Menubar>

  <Sidebar v-model:visible="visibleLeft" dismissable>
    <PanelMenu :model="menuItemsPrime" class="mainMenu"/>
  </Sidebar>

  <router-view/>
</template>

<script>
import authService from "../services/auth.service"
import {getCookie} from "@/utils/helpers/cookies.helpers";
import {decryptText, encryptText} from "@/utils/helpers/crypto.helpers";
import {getDecryptedRoles, decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";
import departmentHead from "@/views/department-head/DepartmentHead.vue";

export default {
  name: "DefaultLayout",

  data() {
    return {
      isMessageVisible: false,
      visibleLeft: false,
      accountItems: [{
        icon: 'pi pi-user',
        items: [
          {
            label: getCookie('USERNAME'),
          },
          {
            separator: true
          },
          {
            label: 'Мой профиль',
            icon: 'pi pi-user',
            to: '/profile',
            visible: ['admin', 'student', 'candidate'].some(decryptCheckRole),
          },
          {
            label: 'Моя информация',
            icon: 'pi pi-id-card',
            to: '/main',
            visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead', 'HRHead'].some(decryptCheckRole),
          },
          {
            label: 'Мои силлабусы',
            icon: 'pi pi-star',
            to: '/syllabus-template',
            visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead', 'qualityCenterMain'].some(decryptCheckRole),
          },
          // {
          //   label: 'Мои договора',
          //   icon: 'pi pi-file',
          //   to: '/my-contracts',
          //   visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead'].some(decryptCheckRole),
          // },
          {
            label: 'Мое расписание',
            icon: 'pi pi-calendar',
            to: '/schedule/my',
            visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead', 'student'].some(decryptCheckRole),
          },
          {
            label: 'Академический календарь',
            icon: 'pi pi-clock',
            to: '/academic-calendar',
            visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead', 'student'].some(decryptCheckRole),
          },
          {
            label: 'Выход',
            icon: 'pi pi-fw pi-power-off',
            command: (event) => {
              this.logout()
            }
          }
        ]
      }
      ],
      menuItems: [

        {
          label: 'Admin',
          icon: 'pi pi-shield',
          items: [
            // {
            //   label: 'Users',
            //   to: '/users',
            //   visible: ['admin']
            // },
            {
              label: 'Organizations',
              to: '/organizations',
              visible: ['admin']
            },
            {
              label: 'Organization joining',
              to: '/organization-joining',
              visible: ['admin']
            },
            {
              label: 'Organization members',
              to: '/organization-members',
              visible: ['admin']
            },
            {
              label: 'Events list',
              to: '/events-list',
              visible: ['admin']
            },
            {
              label: 'Student event registration',
              to: '/student-event-registration',
              visible: ['admin']
            },
          ]
        },
        {
          label: 'News',
          icon: 'pi pi-bell',
          items: [
            {
              label: 'News',
              to: '/news',
              visible: ['admin', 'candidate', 'viceRector', 'commission', 'departmentHead']
            }
          ]
        },
        {
          label: 'Lawyer',
          icon: 'pi pi-star',
          items: [
            {
              label: 'Education Program Change Students',
              to: '/education-program-change-students',
              visible: ['admin', 'lawyer']
            }
          ]
        },
        {
          label: 'F.A.Q.',
          icon: 'pi pi-question',
          items: [
            {
              label: 'Frequently Asked Question',
              to: '/faq',
              visible: ['admin', 'candidate', 'dean', 'viceRector', 'commission', 'departmentHead']
            }
          ]
        },
        {
          label: 'Student Orders',
          icon: 'pi pi-briefcase',
          items: [
            {
              label: 'Student Orders',
              to: '/student-orders',
              visible: ['admin', 'dean', 'rector']
            }
          ]
        },
        {
          label: 'Diploma Reference',
          icon: 'pi pi-briefcase',
          items: [
            {
              label: 'Diploma Reference',
              to: '/diploma-reference',
              visible: ['admin', 'dean']
            },
            {
              label: 'Reference Logs',
              to: '/reference-logs',
              visible: ['admin']
            }
          ]
        },
        {
          label: 'KKK',
          icon: 'pi pi-briefcase',
          items: [
            {
              label: 'Vacancies',
              to: '/vacancies',
              visible: ['admin', 'candidate', 'viceRector', 'commission', 'departmentHead', 'HRHead', 'shareholder']
            },
            {
              label: 'Vacancy applications (Head of Department)',
              to: '/kkk/department-head-vacancy-applications',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'Commission Decision',
              to: '/commission-decision',
              visible: ['admin', 'commission']
            },
            {
              label: 'Commission Head Decision',
              to: '/commission-head-decision',
              visible: ['admin', 'commissionHead']
            },
            {
              label: 'Commission Score',
              to: '/commission-score-new',
              visible: ['admin', 'commission', 'commissionHead']
            },
            {
              label: 'Vacancy applications (HR)',
              to: '/kkk/hr-vacancy-applications',
              visible: ['admin', 'HRHead']
            },
            {
              label: 'HR Score',
              to: '/kkk/hr-score',
              visible: ['admin', 'HRHead']
            },
            {
              label: 'Teachers',
              to: '/kkk/teachers',
              visible: ['admin', 'HRHead', 'rector']
            },
            {
              label: 'Teachers Orders',
              to: '/kkk/teachers-orders',
              visible: ['admin', 'HRHead', 'rector']
            },
            {
              label: 'Teachers Contracts',
              to: '/kkk/teachers-contracts',
              visible: ['admin', 'HRHead', 'rector']
            }
          ]
        },
        {
          label: 'Vacancy',
          icon: 'pi pi-briefcase',
          items: [
            {
              label: 'Vacancies',
              to: '/vacancies',
              visible: []
            },
            {
              label: 'Vacancies (list of applications)',
              to: '/list-of-vacancies',
              visible: []
            },
            {
              label: 'Commission Score',
              to: '/commission-score',
              visible: []
            }
          ]
        },
        {
          label: 'EDS',
          icon: 'pi pi-key',
          items: [
            {
              label: 'Enrollee Signature',
              to: '/enrollee-eds',
              visible: ['admin', 'enrollee']
            },
            {
              label: 'Dean Signature',
              to: '/dean-eds',
              visible: ['admin', 'dean', 'viceRector']
            },
            {
              label: 'Tech Secretary Signature',
              to: '/tech-secretary-eds',
              visible: ['admin', 'tehSecretary']
            },
            {
              label: 'Student Sponsor Signature',
              to: '/student-sponsor-eds',
              visible: ['admin', 'studentSponsor']
            }
          ]
        },
        {
          label: 'EPD',
          icon: 'pi pi-book',
          items: [{
            label: 'Passport of Education Program',
            to: '/passport-of-education-program',
            visible: ['admin', 'departmentHead', 'officeRegistration', 'officeRegistrationHead']
          },
            {
              label: 'Minor Education Program',
              to: '/minor-education-program',
              visible: ['admin', 'departmentHead', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Decree Goso Templates',
              to: '/decree-goso-templates',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            }, {
              label: 'Education Discipline',
              to: '/education-discipline',
              visible: ['admin', 'departmentHead', 'officeRegistration', 'officeRegistrationHead', 'davAssistant', 'educationprogramSupervisor']
            }, {
              label: 'Education Disciplines List',
              to: '/education-disciplines-list',
              visible: ['admin', 'shareholder']
            }, {
              label: 'Education Curriculum',
              to: '/education-curriculum',
              visible: ['admin', 'departmentHead', 'officeRegistration', 'officeRegistrationHead', 'shareholder']
            }, {
              label: 'Time Norm by type of Education Work',
              to: '/credit-time-norm-template',
              visible: ['admin']
            }, {
              label: 'Teaching Load',
              to: '/teaching-load-old',
              visible: ['admin']
            }, {
              label: 'Curriculum Disciplines',
              to: '/curriculum-disciplines',
              visible: ['admin', 'departmentHead', 'officeRegistrationHead', 'officeRegistration']
            },
            {
              label: 'Education Programme Supervisor',
              to: '/education-programme-supervisor',
              visible: ['admin', 'educationprogramSupervisor']
            },
          ]
        }, {
          label: 'Abiturient',
          icon: 'pi pi-users',
          items: [{
            label: 'Abiturient Orders',
            to: '/abiturient-orders',
            visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
          }]
        }, {
          label: 'Office Registration',
          icon: 'pi pi-star',
          items: [
            {
              label: 'Students List',
              to: '/students-list',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Consolidated Statement',
              to: '/consolidated-statement',
              visible: ['admin', 'dean', 'officeRegistration', 'officeRegistrationHead', 'rector']
            },
            {
              label: 'Unite courses',
              to: '/unite-courses',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Written Exam Courses List',
              to: '/written-exam-courses-list',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Test Exam Courses List',
              to: '/test-exam-courses-list',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Document Orders',
              to: '/doc-orders',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Academic Debt',
              to: '/academic-debt/office-registration',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Old discipline bind to new discipline',
              to: '/old-discipline-bind-to-new-discipline',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'NIS and college',
              to: '/nis-college-students',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Transcript for Group',
              to: '/transcript-for-group',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Statement Print',
              to: '/statement-print',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Change discipline credit',
              to: '/change-discipline-credit',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Disciplines of foreign universities',
              to: '/office-registration/disciplines-of-foreign-universities',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Student practice grades',
              to: '/office-registration/student-practice-grades',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Publish courses to moodle',
              to: '/department/publish-courses-to-moodle',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead', 'rector']
            },
            {
              label: 'Prerequisites',
              to: '/office-registration/prerequisites-magistracy',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Student Difference Request',
              to: '/office-registration/student-difference-request',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Open VSK',
              to: '/office-registration/open-vsk',
              visible: ['admin', 'officeRegistrationHead']
            },
            {
              label: 'Email change',
              to: '/office-registration/email-change',
              visible: ['admin', 'officeRegistration']
            },
            {
              label: 'Transcript Orders',
              to: '/office-registration/transcript-orders',
              visible: ['admin', 'officeRegistration']
            },
          ]
        },

        {
          label: 'Documents',
          icon: 'pi pi-book',
          items: [
            {
              label: 'Signing Document',
              to: '/document/signing-document',
              visible: ['admin', 'departmentHead', 'dean']
            }
          ]
        },
        {
          label: 'Head of Department',
          icon: 'pi pi-user',
          items: [
            {
              label: 'Academic Debt',
              to: '/academic-debt/department-head',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'Curriculum Optional Component',
              to: '/department/curriculum-optional-component',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'Practice',
              to: '/department/bind-practice',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'Publish courses',
              to: '/department/publish-courses-to-moodle',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'Students (Prerequisites)',
              to: '/department/prerequisites-students',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'Bind Prerequisite',
              to: '/department/bind-prerequisite',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'Difference Students',
              to: '/department/difference-students',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'Difference',
              to: '/department/difference',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'Show Syllabus',
              to: '/department/show-syllabus',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'Teaching Training Direction',
              to: '/department/pps-teaching-training-direction',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'Consolidated Statement',
              to: '/consolidated-statement',
              visible: ['admin', 'departmentHead']
            },
          ]
        },
        {
          label: 'Student',
          icon: 'pi pi-users',
          items: decryptCheckRole('organizationLeader') ? [
            {
              label: 'My Profile',
              to: '/student/my-profile',
              visible: ['admin', 'student']
            },

            {
              label: 'Student Attestation',
              to: '/student-attestation',
              visible: ['admin', 'student']
            },
            {
              label: 'Student Transcript',
              to: '/student-transcript',
              visible: ['admin', 'student']
            },
            {
              label: 'Student Report',
              to: '/student-report',
              visible: ['admin', 'student']
            },
            {
              label: 'Language Center Disciplines',
              to: '/language-center-disciplines',
              visible: []
            },
            {
              label: 'Make a complaint',
              to: '/complaint-form',
              visible: ['admin', 'student']
            },
            {
              label: 'Graduate work topic',
              to: '/graduate-work-topic',
              visible: ['admin']
            },
            {
              label: 'Upload graduate work',
              to: '/upload-graduate-work',
              visible: ['admin', 'student']
            },
            {
              label: 'Academic Debt',
              to: '/academic-debt/student',
              visible: ['admin', 'student']
            },
            {
              label: 'Summer courses',
              to: '/student/summer',
              visible: ['admin', 'student']
            },
            {
              label: 'Choose disciplines',
              to: '/student/choose-disciplines',
              visible: ['admin', 'departmentHead', 'student']
            },
            {
              label: 'Practice',
              to: '/student/practice',
              visible: ['admin', 'student']
            },
            {
              label: 'Difference',
              to: '/student/difference',
              visible: ['admin', 'student']
            },
            {
              label: 'Difference application',
              to: '/difference/student-application',
              visible: ['admin', 'student']
            },
            {
              label: 'Research cup registration',
              to: '/research-cup/research-cup-registration',
              visible: ['admin', 'student']
            },
            {
              label: 'Organization joining',
              to: '/organization-joining',
              visible: ['admin', 'student']
            },
            {
              label: 'Events list',
              to: '/events-list',
              visible: ['admin', 'student']
            },
            {
              label: 'Organization leader',
              to: '/organization-members',
              visible: ['admin', 'student']
            },
          ]
          :
          [
            {
              label: 'My Profile',
              to: '/student/my-profile',
              visible: ['admin', 'student']
            },

            {
              label: 'Student Attestation',
              to: '/student-attestation',
              visible: ['admin', 'student']
            },
            {
              label: 'Student Transcript',
              to: '/student-transcript',
              visible: ['admin', 'student']
            },
            {
              label: 'Student Report',
              to: '/student-report',
              visible: ['admin', 'student']
            },
            {
              label: 'Language Center Disciplines',
              to: '/language-center-disciplines',
              visible: []
            },
            {
              label: 'Make a complaint',
              to: '/complaint-form',
              visible: ['admin', 'student']
            },
            {
              label: 'Graduate work topic',
              to: '/graduate-work-topic',
              visible: ['admin']
            },
            {
              label: 'Upload graduate work',
              to: '/upload-graduate-work',
              visible: ['admin', 'student']
            },
            {
              label: 'Academic Debt',
              to: '/academic-debt/student',
              visible: ['admin', 'student']
            },
            {
              label: 'Summer courses',
              to: '/student/summer',
              visible: ['admin', 'student']
            },
            {
              label: 'Choose disciplines',
              to: '/student/choose-disciplines',
              visible: ['admin', 'departmentHead', 'student']
            },
            {
              label: 'Practice',
              to: '/student/practice',
              visible: ['admin', 'student']
            },
            {
              label: 'Difference',
              to: '/student/difference',
              visible: ['admin', 'student']
            },
            {
              label: 'Difference application',
              to: '/difference/student-application',
              visible: ['admin', 'student']
            },
            {
              label: 'Research cup registration',
              to: '/research-cup/research-cup-registration',
              visible: ['admin', 'student']
            },
            {
              label: 'Organization joining',
              to: '/organization-joining',
              visible: ['admin', 'student']
            },
          ]
        },
        {
          label: 'SEI',
          icon: 'pi pi-comment',
          items: [
            {
              label: 'Social GPA',
              to: '/social-gpa',
              visible: ['admin', 'student']
            },
            {
              label: 'Big Five',
              to: '/big-five',
              visible: ['admin', 'student']
            },
            {
              label: 'Lusher',
              to: '/luscher',
              visible: ['admin', 'student']
            },
            {
              label: 'EQI Short',
              to: '/eqi-short',
              visible: ['admin', 'student']
            },
            {
              label: 'Imagination',
              to: '/creative-imagination-test',
              visible: ['admin', 'student']
            },
          ]
        },

        {
          label: 'Teacher',
          icon: 'pi pi-user',
          items: [
            {
              label: 'My Courses',
              to: '/my-courses',
              visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead']
            },
            {
              label: 'Summer courses',
              to: '/teacher/summer/courses',
              visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead']
            },
            {
              label: 'Difference courses',
              to: '/teacher/difference/courses',
              visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead']
            },
            {
              label: 'Practice',
              to: '/teacher/practice/grades',
              visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead']
            },
            {
              label: 'Magistracy Prerequisite',
              to: '/teacher/magistracy-prerequisite/grades',
              visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead']
            },
            {
              label: 'My Load Calculation',
              to: '/my-load-calculation',
              visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead']
            },
            {
              label: 'Attendance',
              to: '/teacher/attendance',
              visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead']
            },
          ]
        }, {
          label: 'Head of graduate work',
          icon: 'pi pi-user',
          items: [{
            label: 'Students',
            to: '/graduate-work-students-list',
            visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead']
          }]
        }, {
          label: 'Teacher Hire',
          icon: 'pi pi-user-plus',
          items: [{label: 'Teachers List', to: '/active-directory', visible: ['admin', 'HRHead']}]
        }, {
          label: 'Analytics',
          icon: 'pi pi-chart-bar',
          items: [{
            label: 'PowerBI reports',
            to: '/powerbi-analysis',
            visible: ['admin', 'dean', 'rector', 'viceRector', 'departmentHead', 'scienceManager', 'officeRegistration', 'officeRegistrationHead', 'qualityCenter', 'HRHead', 'davAssistant', 'qualityCenterMain', 'shareholder']
          }, {label: 'Student Attendance', to: '/primevue-student-attendance', visible: ['admin']}]
        }, {
          label: 'Syllabuses',
          icon: 'pi pi-book',
          items: [{label: 'Empty syllabuses', to: '/syllabus-list', visible: ['admin', 'departmentHead', 'dean']}]
        },
        {
          label: 'Language center',
          icon: 'pi pi-star',
          items: [
            {
              label: 'Change student course',
              to: '/change-student-course',
              visible: ['admin']
            },
            {
              label: 'Add student to course',
              to: '/add-to-lang-course',
              visible: ['admin']
            },
            {
              label: 'Choose Students Language Level',
              to: '/choose-students-language-level',
              visible: ['admin', 'departmentHead']
            },
          ]
        },
        {
          label: 'Students',
          icon: 'pi pi-star',
          items: [{
            label: 'Students',
            to: '/students',
            visible: ['admin', 'departmentHead', 'dean', 'studentsSupport', 'lawyer', 'graduateWorkTehSecretary']
          }]
        }, {
          label: 'Inspector Support',
          icon: 'pi pi-ticket',
          items: [{label: 'Complaints List', to: '/complaints-list', visible: ['admin', 'inspectorSupport']}]
        }, {
          label: 'Graduate Work',
          icon: 'pi pi-flag',
          items: [{
            label: 'Graduate Work Calendar',
            to: '/graduate-work-calendar',
            visible: ['admin', 'dean']
          }, {
            label: 'Graduate Work Theme',
            to: '/graduate-work-theme',
            visible: ['admin', 'teacher']
          }, {
            label: 'Graduate Work Temp',
            to: '/graduate-work-temp',
            visible: ['admin', 'departmentHead']
          },
            {
              label: 'Graduate Work Complex Exam',
              to: '/graduate-work-exam',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'Attestation commission',
              to: '/department/attestation-commission',
              visible: ['admin', 'departmentHead']
            }, {
              label: 'Graduate Work Score',
              to: '/graduate-work-score',
              visible: ['admin', 'departmentHead', 'graduateWorkChairman', 'graduateWorkTehSecretary', 'graduateWorkCommissionMember']
            }]
        }, {
          label: 'Load Calculation',
          icon: 'pi pi-percentage',
          items: [{
            label: 'Load Calculation',
            to: '/teaching-load/disciplines',
            visible: ['admin', 'departmentHead']
          },
            {
              label: 'Load Calculation (Language Center)',
              to: '/teaching-load/language-disciplines',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'Graduate Work Load Calculation',
              to: '/load-calculation',
              visible: ['admin', 'departmentHead']
            }, {label: 'Adviser Choose', to: '/department/adviser-choose', visible: ['admin', 'departmentHead']}]
        }, {
          label: 'Forecast Load Calculation',
          icon: 'pi pi-calendar',
          items: [{
            label: 'Load Calculation',
            to: '/department/disciplines-for-teaching-load',
            visible: ['admin', 'departmentHead']
          }, {label: 'Office hours', to: '/department/office-hours', visible: ['admin', 'departmentHead']}]
        }, {
          label: 'Conference',
          icon: 'pi pi-users',
          items: [{
            label: 'Conference Author',
            to: '/conference/conference-author',
            visible: ['admin', 'candidate', 'viceRector', 'reviewer']
          }, {
            label: 'Conference Coordinator',
            to: '/conference/conference-coordinator',
            visible: ['admin', 'conferenceModerator']
          }, {
            label: 'Section Moderator',
            to: '/conference/section-moderator',
            visible: ['admin', 'sectionModerator']
          }, {label: 'Conference Reviewer', to: '/conference/conference-reviewer', visible: ['admin', 'reviewer']}]
        },
        {
          label: 'Adviser',
          icon: 'pi pi-user',
          items: [
            {
              label: 'Groups',
              to: '/adviser/groups',
              visible: ['admin']
            },
            {
              label: 'Choose students',
              to: '/adviser/choose-students',
              visible: ['admin', 'adviser']
            }
          ]
        },
        {
          label: 'Graduate data',
          icon: 'pi pi-user',
          items: [{
            label: 'Graduate data',
            to: '/graduate-data',
            visible: ['admin', 'HRHead', 'davAssistant', 'officeRegistration']
          }]
        }, {
          label: 'Schedule',
          icon: 'pi pi-calendar',
          items: [
            {
              label: 'Manager',
              to: '/schedule/manager',
              visible: ['admin', 'scheduleManager', 'officeRegistration', 'officeRegistrationHead', 'rector']
            },
            {
              label: 'Teacher',
              to: '/schedule/teacher',
              visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead']
            },
            {
              label: 'Student',
              to: '/schedule/student',
              visible: ['admin', 'student']
            },
          ]
        },
        {
          label: 'Research Cup',
          icon: 'pi pi-video',
          items: [
            {
              label: 'Registered Students List',
              to: '/research-cup/registered-students-list',
              visible: ['admin', 'deputyDean']
            },
            {
              label: 'Steps Score',
              to: '/research-cup/steps-score',
              visible: ['admin', 'deputyDean', 'chairmanResearchCup', 'commissionResearchCup']
            },
            {
              label: 'Dean',
              to: '/student-research-cup-dean',
              visible: ['admin', 'dean']
            },
          ]
        },
        {
          label: 'Academic Calendar',
          icon: 'pi pi-calendar',
          items: [
            {
              label: 'Education Calendar',
              to: '/education-calendar',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Semester Summer Calendar',
              to: '/semester-summer-calendar',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Semester Calendar',
              to: '/semester-calendar',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Holiday Calendar',
              to: '/holiday-calendar',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },
            {
              label: 'Choose Discipline Calendar',
              to: '/choose-discipline-calendar',
              visible: ['admin', 'officeRegistration', 'officeRegistrationHead']
            },

          ]
        },

        {
          label: 'PPS and Administrations',
          icon: 'pi pi-users',
          items: [
            {
              label: 'Change photo',
              to: '/change-photo-pps',
              visible: ['admin', 'marketer', 'assistant']
            },
          ]
        },
        {
          label: 'KPI',
          icon: 'pi pi-briefcase',
          items: [
            {
              label: 'KPI teacher',
              to: '/kpi-teacher',
              visible: ['admin', 'candidate', 'dean', 'rector', 'viceRector', 'commission', 'departmentHead']
            },
            {
              label: 'KPI teachers department head',
              to: '/kpi-teachers-department-head',
              visible: ['admin', 'departmentHead']
            },
            {
              label: 'KPI commission',
              to: '/kpi-commission',
              visible: ['admin', 'kpiCommission']
            },
          ]
        },
        {
          label: 'Youth Center',
          icon: 'pi pi-globe',
          items: [
            {
              label: 'Organizations',
              to: '/organizations',
              visible: ['admin', 'youthCenter',]
            },
            {
              label: 'Events',
              to: '/dmp-events',
              visible: ['admin', 'youthCenter',]
            },
          ]
        },
        {
          label: 'Winter Science School',
          icon: 'pi pi-slack',
          items: [
            {
              label: 'Winter Science School QR',
              to: '/winter-science-school-qr',
              visible: ['admin', 'winterScienceSchoolAdmin']
            },
            {
              label: 'Attendance List',
              to: '/winter-science-school-attendance-list',
              visible: ['admin', 'winterScienceSchoolAdmin']
            },
            {
              label: 'Certificate Holders List',
              to: '/winter-science-school-certificate-holders-list',
              visible: ['admin', 'winterScienceSchoolAdmin', 'designer']
            },
          ]
        },
        {
          label: 'Predict',
          icon: 'pi pi-sliders-v',
          items: [
            {
              label: 'Predict List',
              to: '/predict-list',
              visible: ['admin', 'rector', 'dean', 'departmentHead']
            },
          ]
        },
      ],
      user: {},
      userDebts: [],
      menuItemsPrime: [],
    }
  },
  computed: {
    error() {
      return this.$store.getters.error
    },

  },
  watch: {
    error(err) {
      this.$error({title: `Ошибка ${err.message}`, text: JSON.stringify(err.errors)})
    }
  },
  methods: {
    logout() {
      authService.logout()
    },
    // checkRole(i) {
    //   const roles = JSON.parse(getCookie('ROLES')) || []
    //   if (!roles.length && getCookie('ROLE')) {
    //     roles.push(getCookie('ROLE'))
    //   }
    //   return roles.includes(i)
    // },
    // decryptCheckRole(i) {
    //   let roles = getDecryptedRoles()
    //   return roles.includes(i)
    // },
    setMenuItems() {
      let menuItemsPrime = []

      this.menuItems.forEach(mHead => {
        let roles = []

        for (let r of [...mHead.items.map(mChild => mChild.visible)]) {
          roles.push(...r)
        }

        let headVisible = [...new Set(roles)].some(decryptCheckRole) || false

        menuItemsPrime.push({
          label: mHead.label,
          icon: mHead.icon,
          visible: headVisible,
          items: mHead.items.map(mChild => ({
            label: mChild.label,
            to: mChild.to,
            visible: mChild.visible.some(decryptCheckRole),
            command: (event) => {
              this.visibleLeft = false
            }
          }))
        })
      })

      this.menuItemsPrime = menuItemsPrime
    }

  },
  async mounted() {
    // (function (w, d, u) {
    //   var s = d.createElement('script');
    //   s.async = true;
    //   s.src = u + '?' + (Date.now() / 60000 | 0);
    //   var h = d.getElementsByTagName('script')[0];
    //   h.parentNode.insertBefore(s, h);
    // })(window, document, 'https://bitrix24.uib.kz/upload/crm/site_button/loader_3_qjnnb9.js');

    this.setMenuItems()

    this.user = await authService.getUser()
    this.userDebts = await authService.getDebts()
  },
}

</script>
<style scoped>
span.site-name {
  position: absolute;
  margin-top: 5px;
}

.notification-header {
  background-color: #393E46;
  color: #f0f0f0;
  width: 100%;
}

.news-header {
  color: #fff;
  width: 100%;
}
</style>
